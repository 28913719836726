import React, { FC } from "react";

interface Props {
  img: string;
}

const ParallaxDivider: FC<Props> = ({ img }) => {
  return (
    <>
      <div
        className="hidden md:flex h-screen bg-no-repeat bg-fixed bg-cover"
        style={{
          backgroundImage: `url(${img})`,
        }}
      />
      <img src={img} className="block md:hidden" alt="" />
    </>
  );
};

export default ParallaxDivider;
