import React, {FC} from "react";

interface Props {
    text: string;
    author: string;
    img: string;
}

const Blockquote: FC<Props> = ({
                                   text,
                                   img,
                                   author
                               }) => {
    return (
        /*        <div className="">
                    <div className="flex">
                    <blockquote className="blockstyle">
                        {text}
                    </blockquote>
                    <div className="">
                        <img src={img} className="rounded-circle w-50 slide-right" alt=""/>
                    </div>
                    </div>
                    <div className="float-right">
                        <strong>{author}</strong>
                    </div>
                </div>*/
        <div className="px-5 md:px-60">
            <div>
                <blockquote className="blockstyle">
                    {text}
                </blockquote>
            </div>
            <div>
                    <img src={img} className="rounded-circle w-50 mx-auto" alt=""/>
            </div>
            <div>
                <div className="mx-auto pt-2 text-center">
                    <strong>{author}</strong>
                </div>
            </div>
        </div>
    );
}

export default Blockquote;
