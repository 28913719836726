import React from 'react';
import SketchImg from '../assets/Sketch_Plan.png';
import Sketch3D from '../assets/Sketch_3D_Final.png';
import BlockQuoteSignature from '../assets/RUBuilds.png';
import RowTextImg from "../components/RowTextImg";
import RowImgText from "../components/RowImgText";
import Blockquote from "../components/Blockquote";

function Sketch() {
    return (
        <>
            <section className="page-section bg-white">
                <div className="container px-5 py-24 mx-auto">
                    <div className="md:px-48">
                        <RowTextImg
                        text={"Разположението на сградата следва конфигурацията на имота с цел повече озеленено дворно пространство и панорамни гледки. Денивелацията на терена и разположението на сградите позволяват видимост към Витоша и София от всички апартаменти, ресторанта и фитнеса. "}
                        img={SketchImg}/>
                    <RowImgText
                        text={"Обемно-пространственото решение представлява композиция от два триетажни обема. Търсена е композиционна игра на своеобразни правоъгълни отвори към гледките чрез лоджиите, допълнено с цветни акценти от елементи с дървесен ефект.\n"}
                        img={Sketch3D}/>
                    </div>
                    <Blockquote author={"доц. д-р арх. Евгени Рафаилов"} img={BlockQuoteSignature} text={"Полученият контраст между силуета на планината, средата и двора с идеята на сградата е съзнателно търсен, а логиката е продължена не само с формите и пропорциите, но и при композирането на материалите. Контрастът между светло и тъмно, между плът и стъкло, между строгата геометрична линия и тази на околния силует е търсената философия, благодарение на която може да се подчертае и сградата, и средата. \n"}/>
                </div>
            </section>
        </>
    );
}

export default Sketch;
