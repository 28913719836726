import React from 'react';
import King from '../assets/king.png';

function Project() {
    return (
        <>
            <section className="page-section bg-gold">
                <div className="container mx-auto flex px-11 md:px-24 md:py-24 items-center justify-center flex-col">
                    <div className="md:w-2/3 w-full text-white">
                        <p className="text-center text-5xl md:text-7xl mb-6">ПРОЕКТЪТ</p>
                        <p className="text-justify mb-10 text-lg tracking-wide">THE ROYAL RESIDENCE е жилищен комплекс, предоставящ уют,
                            комфорт и качествена среда за живот. В полите на Витоша, с гледка към планината и светлините
                            на града, той съчетава в себе си съвременна архитектура, изпълнена с качествени и иновативни
                            материали, нови технологии и добро планиране, превръщайки го в перфектния дом. Комфортът на
                            обитаване се допълва от богато озеленяване и паркова среда, а за високото ниво на
                            предложените услуги говорят предвиденият луксозен ресторант и фитнес с представителното им
                            фоайе.</p>
                    </div>
                    <img alt="The Royal Residence" src={King}/>
                </div>
            </section>
        </>
    );
}

export default Project;
