import React, {FC} from 'react';
import PrimaryHeading from "../components/typography/PrimaryHeading";
import PrimarySubtitle from "../components/typography/PrimarySubtitle";

import Material1 from "../assets/materials/1.png";
import Material2 from "../assets/materials/2.png";
import Material3 from "../assets/materials/3.png";
import Material4 from "../assets/materials/4.svg";
import Material5 from "../assets/materials/5.svg";
import Material6 from "../assets/materials/6.svg";
import Material7 from "../assets/materials/7.svg";
import RectangleButton from "../components/buttons/RectangleButton";
import LazyLoad from "react-lazy-load";

interface Props {
    pageRefs: any
}

const Materials: FC<Props> = ({pageRefs}) => {
    return (
        <>
            <div ref={el => pageRefs.current = {...pageRefs.current, materials: el}} id="materials">
                <section className="page-section bg-white">
                    <div className="container mx-auto pt-5 pb-5 md:px-48">
                        <PrimaryHeading heading={"Материали и технологии"}/>
                        <PrimarySubtitle
                            text={<>  В основата на екстериорното решение е заложена фасадна облицовка <strong>LAMINAM®</strong> в два цвята. Съчетани с райе от греди на <strong>Plasticwood®</strong> придават на фасадата на сградата нужната индивидуалност и модерна архитектура с нестандартен и сложен детайл. Всичко това е допълнено с дизайнерско фасадно осветление. Вратите на главните входове са изработени по поръчка с вградена моторика на <strong>Schuko</strong> и по специален архитектурен детайл в черно и дърво. Пространствата пред главните входове са решени с термолющен гранит, а алеите в тревните площи са решени с големи свободно поставени каменни плочи. В архитектурното рeшeниe е предвидено покриване нa вcичĸи тepacи.</>}/>

                        <div className="flex mx-auto justify-center gap-8 pb-14">
                            <div className=""><LazyLoad height="100"><img src={Material1} alt=""/></LazyLoad></div>
                            <div className=""><LazyLoad height="100"><img src={Material2} alt=""/></LazyLoad></div>
                            <div className=""><LazyLoad height="100"><img src={Material3} alt=""/></LazyLoad></div>
                        </div>

                        <PrimarySubtitle
                            text={<>Πpoeĸтиpaни ca виcoкoтexнoлoгични и eфeĸтивни cиcтeми зa пoддъpжaнe нa миĸpoĸлимaтa
                                в цялaтa
                                cгpaдa, ĸoитo ca eнepгocпecтявaщи и c нискоемисионно cъдъpжaниe нa въглepoдeн диoкcид –
                                термопомпи <strong>Daikin</strong> за отопление и охлаждане с хидромодул, както и подово
                                отопление.</>}/>

                        <PrimarySubtitle
                            text={<>Предвидените асансьори са най-висок клас за 8 души с широки дизайнерски
                                кабини.</>}/>

                        <div
                            className="mx-auto my-auto text-center justify-center grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 pt-10">
                            <div className="mx-auto">
                                <LazyLoad height="150"><img src={Material4} className="w-24 md:w-36 mx-auto" alt="Висок клас асансьори"/></LazyLoad>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Висок клас асансьори</div>
                            </div>
                            <div className="mx-auto">
                                <LazyLoad height="150"><img src={Material5} className="w-24 md:w-36 mx-auto" alt="Daikin термопомпи"/></LazyLoad>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Daikin термопомпи
                                </div>
                            </div>
                            <div className="mx-auto">
                                <LazyLoad height="150"><img src={Material6} className="w-24 md:w-36 mx-auto" alt="Подово отопление"/></LazyLoad>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Подово отопление</div>
                            </div>
                            <div className="mx-auto">
                                <LazyLoad height="150"><img src={Material7} className="w-24 md:w-36 mx-auto" alt="Schuko дограми"/></LazyLoad>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Schuko дограми</div>
                            </div>
                        </div>

                        <div className="pt-14 text-center">
                            <RectangleButton text={"Разгледайте други акценти"} hrefTo={"/accents"} />
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Materials;
