import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHouse,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import * as emailjs from "emailjs-com";
import { validateEmail } from "../utils/emailValidation";

const Footer: FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [apartmentName, setApartmentName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const getApartmentType = (value: string) => {
    if (value === "1") return "1 Спалня";
    if (value === "2") return "2 Спални";
    if (value === "3") return "3 Спални";

    return "";
  };

  async function handleClick() {
    const formData = {
      from_name: `${firstName} ${lastName}`,
      from_phone: number,
      from_email: email,
      type_apartment: getApartmentType(apartmentName),
      message: message,
    };
    const isValidEmail = validateEmail(email);

    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      number === "" ||
      message === "" ||
      !isValidEmail ||
      getApartmentType(apartmentName) === ""
    ) {
      alert("Моля, попълнете всички полета, преди да изпратите запитване.");
    } else {
      try {
        const response = await emailjs.send(
          "service_wwf38kv",
          "template_kn1mr8o",
          formData,
          "user_J0OCZPcQJ6nR17qIj42Lv"
        );
        if (response) {
          alert(
            "Вашето запитване беше изпратено успешно! Очаквайте отговор от екипа на The Royal скоро."
          );
        }
        console.log(response);
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <div id="contacts">
      <div className="py-4 lg:py-8 bg-white text-gray-700">
        <div className="xl:mx-auto xl:container ">
          {/* <div className="flex flex-wrap xl:mx-auto xl:container">
            <div className="w-full lg:w-1/2 xl:mt-10 mb-10 2xl:pr-24 2xl:pl-0 xl:pl-12 pl-0 ">
              <div className="w-full flex flex-col items-start  xl:justify-start  z-20 xl:px-0 px-4 xl:py-0 py-4">
                <div className="w-full 2xl:pl-48 xl:pt-1">
                  <p className="text-base md:text-lg text-gold leading-8 tracking-widest">
                    Имате въпроси?
                  </p>
                  <p className="text-2xl md:text-2xl text-gray-800 lg:text-4xl font-bold tracking-widest">
                    Данни за контакт
                  </p>
                  <div className="w-full md:w-10/12 mt-3">
                    <div className="mt-4 md:mt-8 flex gap-2 items-center">
                      <div className="flex text-3xl text-gold">
                        <FontAwesomeIcon icon={faPhone} />
                      </div>
                      <div className="flex font-bold tracking-widest hover:text-amber-900">
                        <a href="tel:+359 888 661 399">+359 888 661 399</a>
                      </div>
                    </div>
                    <div className="mt-4 md:mt-8 flex gap-2 items-center">
                      <div className="flex text-3xl text-gold">
                        <FontAwesomeIcon icon={faHouse} />
                      </div>
                      <div className="flex font-bold tracking-widest">
                        <p>гр. София, ул. „Лунна папрат“ 1</p>
                      </div>
                    </div>
                    <div className="mt-4 md:mt-8 flex gap-2 items-center">
                      <div className="flex text-3xl text-gold">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                      <div className="flex font-bold tracking-widest">
                        <p>vatanasov@theroyal.bg</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full 2xl:pl-48 xl:pt-1">
                  <div className="w-full md:w-10/12 mt-3 hidden md:block">
                    <iframe
                      title="frame"
                      className="pt-3 map"
                      src="https://maps.google.com/maps?q=1%20%D1%83%D0%BB%D0%B8%D1%86%D0%B0%20%E2%80%9E%D0%9B%D1%83%D0%BD%D0%BD%D0%B0%20%D0%BF%D0%B0%D0%BF%D1%80%D0%B0%D1%82%E2%80%9C&t=&z=15&ie=UTF8&iwloc=&output=embed"
                      allowFullScreen
                      width="600"
                      height="450"
                    ></iframe>
                  </div>
                  <div className="w-full md:w-10/12 mt-3 block md:hidden">
                    <iframe
                      title="frame"
                      className="pt-3 map"
                      src="https://maps.google.com/maps?q=1%20%D1%83%D0%BB%D0%B8%D1%86%D0%B0%20%E2%80%9E%D0%9B%D1%83%D0%BD%D0%BD%D0%B0%20%D0%BF%D0%B0%D0%BF%D1%80%D0%B0%D1%82%E2%80%9C&t=&z=15&ie=UTF8&iwloc=&output=embed"
                      allowFullScreen
                      width="100%"
                      height="100%"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2   xl:pt-10 lg:pl-24">
              <div className="flex flex-col items-start xl:justify-start 2xl:justify-end xl:px-0 px-4">
                <p className="text-base md:text-lg text-gold leading-8 tracking-widest">
                  Запитване за предпочитани апартаменти
                </p>
                <p className="text-2xl mt-3 md:text-2xl text-gray-800 lg:text-4xl font-bold tracking-widest">
                  Запитване
                </p>
                <div className="w-full 2xl:w-8/12 mt-3">
                  <div className="flex">
                    <div className="md:mt-8 w-full">
                      <select
                        onChange={(event) =>
                          setApartmentName(event.target.value)
                        }
                        className="md:mt-3 text-base border-2 w-full lg:w-full xl:w-full hover:border-yellow-500 focus:border-yellow-700 focus:outline-none border-black py-3 pl-4 text-gray-800"
                      >
                        <option value="0">Предпочитан апартамент</option>
                        <option value="1">Една спалня</option>
                        <option value="2">Две спални</option>
                        <option value="3">Три спални</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mt-4 md:mt-8">
                      <p className="text-base font-bold">Име</p>
                      <input
                        className="mt-3 text-base border-2 w-full lg:w-full xl:w-full hover:border-yellow-500 focus:border-yellow-700 focus:outline-none border-black py-3 pl-4 text-gray-800"
                        type="text"
                        name="first_name"
                        placeholder="Вашето име"
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                    </div>
                    <div className="mt-4 md:mt-8">
                      <p className="text-base font-bold">Фамилия</p>
                      <input
                        className="mt-3 text-base border-2 ml-1 w-full lg:w-full xl:w-full hover:border-yellow-500 focus:border-yellow-700 focus:outline-none border-black py-3 pl-4 text-gray-800"
                        type="email"
                        name="last_name"
                        placeholder="Вашата фамилия"
                        onChange={(event) => setLastName(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mt-4 md:mt-8">
                      <p className="text-base font-bold">Е-майл</p>
                      <input
                        className="mt-3 text-base border-2 w-full lg:w-full xl:w-full hover:border-yellow-500 focus:border-yellow-700 focus:outline-none border-black py-3 pl-4 text-gray-800"
                        type="email"
                        name="email"
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder="example@gmail.com"
                      />
                    </div>
                    <div className="mt-4 md:mt-8">
                      <p className="text-base font-bold">Тел. номер</p>
                      <input
                        className="mt-3 text-base ml-1 border-2 w-full lg:w-full xl:w-full hover:border-yellow-500 focus:border-yellow-700 focus:outline-none border-black py-3 pl-4 text-gray-800"
                        type="text"
                        name="phone"
                        placeholder="Вашият телефон"
                        onChange={(event) => setNumber(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-8">
                    <p className="text-base font-bold">Запитване</p>
                    <textarea
                      className="mt-3 text-base border-2 w-full lg:w-full xl:w-full resize-none hover:border-yellow-500 focus:border-yellow-700 focus:outline-none border-black xl:h-40 py-3 pl-4 text-gray-800"
                      placeholder=""
                      name="message"
                      onChange={(event) => setMessage(event.target.value)}
                    />
                  </div>
                  <div className="py-5">
                    <div>
                      Изпращайки запитване, вие автоматично се съгласявате с
                      нашата{" "}
                      <span className="text-amber-600 cursor-pointer hover:text-amber-900">
                        <a
                          rel="noreferrer"
                          href="https://www.theroyal.bg/politika.pdf"
                        >
                          политика за поверителност
                        </a>
                      </span>
                      .
                    </div>
                  </div>
                  <button
                    onClick={handleClick}
                    className="py-3 md:py-5 px-5 md:px-10 bg-gold float-left text-white hover:opacity-90 ease-in duration-150 text-sm md:text-lg tracking-wider font-semibold"
                  >
                    Изпрати запитване
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          <p className="pt-5 text-center font-bold">
            The Royal Residence© 2022
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
