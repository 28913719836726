import {useEffect, useRef} from 'react';

const useVideoAutoPlayback = (options: any) => {
    const containerRef = useRef(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const cb = (entries: [any]) => {
        const [entry] = entries;
        if (entry.isIntersecting) videoRef.current?.play();
        else videoRef.current?.pause();
    };
    useEffect(() => {
        const observer = new IntersectionObserver(() => cb, options);
        const {current} = containerRef;
        if (current) observer.observe(current);
        return () => {
            if (current) observer.unobserve(current);
        };
    }, [containerRef, options]);
    return [containerRef, videoRef];
};

export { useVideoAutoPlayback };
