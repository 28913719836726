import React, {SyntheticEvent, useState} from 'react';

import PrimaryHeading from "../components/typography/PrimaryHeading";
import FinishedVideoPoster from "../assets/finishedvideoposter.png";
import {useVideoAutoPlayback} from "../components/useVideoAutoPlayback";

function FinishedProjects() {

    const [containerRef, videoRef] = useVideoAutoPlayback({
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
    });

    const [isVideoEnd, setVideoEnd] = useState(false);

    function onVideoEnded(event: SyntheticEvent<HTMLVideoElement>) {
        setVideoEnd(true);
    }


    return (
        <>
            <section className="page-section bg-white" ref={containerRef}>
                <div className="mx-auto pt-5 ">
                    <div className="py-2 md:px-40">
                        <PrimaryHeading heading={"Завършени проекти"}/>
                    </div>
                    <div className="lg:w-2/3 mx-auto">
                        <div className="hidden md:flex flex-wrap w-full px-10 mb-4">
                            <video
                                ref={videoRef}
                                autoPlay
                                className={isVideoEnd ? 'hidden' : 'object-cover w-full'}
                                muted
                                onEnded={onVideoEnded}
                                width={"100%"}
                                controls
                            >
                                <source src={"https://www.theroyal.bg/finished.mp4"}/>
                            </video>

                            <img src={FinishedVideoPoster} alt="header"
                                 className={isVideoEnd ? 'object-cover w-full' : 'hidden'}/>

                            <div className="text-center w-full">
                                <div className="text-3xl tracking-wider text-black font-bold title-font mb-10"/>
                                <a
                                    href="https://park.theroyal.bg/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`px-1 md:px-5 py-5 bg-gold text-white hover:opacity-90 ease-in duration-150 text-sm md:text-lg tracking-wider font-bolder mt-2`}>
                                    Разгледай завършеният ни проект
                                </a>
                            </div>
                        </div>

                        <div className={`flex md:hidden flex-wrap w-full px-10 mb-4`}>
                            <video
                                className={""}
                                autoPlay
                                muted
                                controls>
                                <source src={"https://www.theroyal.bg/mobile-finished.mp4"}/>
                            </video>

                            <div className="text-center w-full">
                                <div className="text-3xl tracking-wider text-black font-bold title-font mb-10"/>
                                <a
                                    href="https://park.theroyal.bg/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`px-1 md:px-5 py-5 bg-gold text-white hover:opacity-90 ease-in duration-150 text-sm md:text-lg tracking-wider font-bolder mt-2`}>
                                    Разгледай завършеният ни проект
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FinishedProjects;