import React, { useEffect } from "react";
import PrimaryContainer from "../layouts/PrimaryContainer";

import PrimaryHeading from "../components/typography/PrimaryHeading";
import Carousel from "nuka-carousel";
import IntReshenie1 from "../assets/int-resheniq/1.webp";
import IntReshenie2 from "../assets/int-resheniq/2.webp";
import IntReshenie3 from "../assets/int-resheniq/3.webp";
import IntReshenie4 from "../assets/int-resheniq/4.webp";
import IntReshenie5 from "../assets/int-resheniq/5.webp";
import IntReshenie6 from "../assets/int-resheniq/6.webp";
import IntReshenie7 from "../assets/int-resheniq/7.webp";
import IntReshenie8 from "../assets/int-resheniq/8.webp";
import IntReshenie9 from "../assets/int-resheniq/9.webp";
import IntReshenie10 from "../assets/int-resheniq/10.webp";
import IntReshenie11 from "../assets/int-resheniq/11.webp";
import IntReshenie12 from "../assets/int-resheniq/12.webp";
import IntReshenie13 from "../assets/int-resheniq/13.webp";
import NavbarSecond from "../layouts/NavbarSecond";
import Footer from "../layouts/Footer";
import LazyLoad from "react-lazy-load";

function Gallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PrimaryContainer>
        <div className="the-royal pt-10">
          <NavbarSecond />
          <section className="">
            <div className="w-full h-full mx-auto pt-5 pb-5">
              <div className="p-5">
                <PrimaryHeading heading={"Примерни интериорни решения"} />

                <div className="py-7 text-center">
                  <a
                    href="https://www.theasys.io/viewer/rJ7K1PJwKHV0cpM6glGJEy3Ldi8dBc/"
                    target="_blank"
                    rel="noreferrer"
                    className={`px-1 md:px-5 py-5 bg-gold text-white hover:opacity-90 ease-in duration-150 text-sm md:text-lg tracking-wider font-bolder mt-2`}
                  >
                    Лоби пространството в 3D среда
                  </a>
                </div>

                <Carousel autoplay animation={"fade"}>
                  <div className="h-full">
                    <img src={IntReshenie1} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie2} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie3} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie4} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie5} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie6} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie7} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie8} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie9} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie10} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie11} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie12} alt="Интериорно решение" />
                  </div>
                  <div className="h-full">
                    <img src={IntReshenie13} alt="Интериорно решение" />
                  </div>
                </Carousel>
              </div>
            </div>
          </section>
        </div>
      </PrimaryContainer>
      <Footer />
    </>
  );
}

export default Gallery;
