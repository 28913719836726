import React, {FC} from "react";

interface Props {
    text: string;
    link: string;
}

const CustomButton: FC<Props> = ({
                                     text,
                                     link,
                                 }) => {

    const handleClick = () => {
        window.open(link);
    };

    return (
        <button className="custom-button uppercase" onClick={handleClick}>{text}</button>
    );
}

export default CustomButton;
