import React, { FC, useEffect, useState } from "react";
import Logo from "../components/Logo";
import { Link, useNavigate } from "react-router-dom";
import { scrollToRef } from "../utils/scrollToRef";

interface Props {
  pageRefs?: any;
}

const Navbar: FC<Props> = ({ pageRefs }) => {
  const contactsY = 20536;
  const navigate = useNavigate();

  function handleScrollToElement(event: Event | undefined) {
    if (window.scrollY === 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }

  const [show, setShow] = useState(true);
  const [mobileNav, setMobileNav] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollToElement);
  });

  const mobileMenu = () => {
    setMobileNav(!mobileNav);
  };

  const linkScrollY = (prop: string, y: number) => {
    setTimeout(() => window.scrollTo(0, y), 500);
    if (mobileNav) {
      setMobileNav(!mobileNav);
    }
  };

  const mobileMenuScroll = (prop: string) => {
    scrollToRef(pageRefs, prop);
    setMobileNav(!mobileNav);
  };

  const navigateLinkTop = (prop: string) => {
    navigate(prop);
    setTimeout(() => window.scrollTo(0, 0), 100);
  };

  const onLinkClick = (prop: string) => {
    scrollToRef(pageRefs, prop);
  };

  return (
    <>
      <div
        className={
          show
            ? "flex top-0 fixed z-10 w-full p-5 bg-transparent text-gray-400 justify-between md:px-16 px-5"
            : "flex top-0 fixed z-10 w-full p-5 bg-black text-gray-400 justify-between md:px-16 px-5"
        }
      >
        <Logo />
        <div className="flex">
          <button
            onClick={mobileMenu}
            className={
              mobileNav
                ? "hidden"
                : "mobile-menu-button md:hidden text-gray-400 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-lg inline-flex items-center justify-center"
            }
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div className="hidden md:flex lg:flex xl:flex md:gap-5 md:w-auto md:order-1 cursor-pointer">
          <div
            className="hover:text-gray-600"
            onClick={() => onLinkClick("home")}
          >
            Начало
          </div>
          <div
            className="hover:text-gray-600"
            onClick={() => onLinkClick("mqsto")}
          >
            Местоположение
          </div>
          <div
            className="hover:text-gray-600"
            onClick={() => onLinkClick("facilities")}
          >
            Удобства
          </div>
          <div
            className="hover:text-gray-600"
            onClick={() => onLinkClick("materials")}
          >
            Материали и технология
          </div>
          <div
            className="hover:text-gray-600"
            onClick={() => navigateLinkTop("/accents")}
          >
            Акценти
          </div>
          <div
            className="hover:text-gray-600"
            onClick={() => navigateLinkTop("/gallery")}
          >
            Галерия
          </div>
          {/* <div
            className="hover:text-gray-600"
            onClick={() => linkScrollY("contacts", contactsY)}
          >
            Контакти
          </div> */}
        </div>

        <div className={mobileNav ? "block" : "hidden"}>
          <div className="bg-black w-screen h-screen">
            <div className="flex">
              <button onClick={mobileMenu}>
                <svg
                  version="1.1"
                  fill="#fff"
                  className="w-7"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 475.2 475.2"
                >
                  <g>
                    <g>
                      <path
                        d="M405.6,69.6C360.7,24.7,301.1,0,237.6,0s-123.1,24.7-168,69.6S0,174.1,0,237.6s24.7,123.1,69.6,168s104.5,69.6,168,69.6
			s123.1-24.7,168-69.6s69.6-104.5,69.6-168S450.5,114.5,405.6,69.6z M386.5,386.5c-39.8,39.8-92.7,61.7-148.9,61.7
			s-109.1-21.9-148.9-61.7c-82.1-82.1-82.1-215.7,0-297.8C128.5,48.9,181.4,27,237.6,27s109.1,21.9,148.9,61.7
			C468.6,170.8,468.6,304.4,386.5,386.5z"
                      />
                      <path
                        d="M342.3,132.9c-5.3-5.3-13.8-5.3-19.1,0l-85.6,85.6L152,132.9c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1
			l85.6,85.6l-85.6,85.6c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.6-85.6l85.6,85.6c2.6,2.6,6.1,4,9.5,4
			c3.5,0,6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1l-85.4-85.6l85.6-85.6C347.6,146.7,347.6,138.2,342.3,132.9z"
                      />
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </button>
            </div>

            <div className="mx-16 pt-28 text-2xl">
              <div className="pb-8">
                <Logo />
              </div>

              <div
                className="hover:text-gray-600"
                onClick={() => mobileMenuScroll("home")}
              >
                Начало
              </div>
              <div
                className="hover:text-gray-600"
                onClick={() => mobileMenuScroll("mqsto")}
              >
                Местоположение
              </div>
              <div
                className="hover:text-gray-600"
                onClick={() => mobileMenuScroll("facilities")}
              >
                Удобства
              </div>
              <div
                className="hover:text-gray-600"
                onClick={() => mobileMenuScroll("materials")}
              >
                Материали и технология
              </div>
              <div>
                <Link className="hover:text-gray-600" to={"/accents"}>
                  Акценти
                </Link>
              </div>
              <div>
                <Link className="hover:text-gray-600" to={"/gallery"}>
                  Галерия
                </Link>
              </div>
              <div
                className="hover:text-gray-600"
                onClick={() => linkScrollY("contacts", contactsY)}
              >
                Контакти
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
