import { FC } from "react";
import StickyButton from "../components/StickyButton";

interface Props {
  children: JSX.Element;
}

const PrimaryContainer: FC<Props> = ({ children }) => {
  return (
    <>
      <div className={"primary-container"}>{children}</div>
      {/* <StickyButton /> */}
    </>
  );
};
export default PrimaryContainer;
