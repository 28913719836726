import React from "react";

import Interiorni1 from "../assets/int-resheniq/3.webp";
import Interiorni2 from "../assets/int-resheniq/4.webp";
import Interiorni3 from "../assets/int-resheniq/5.webp";
import Interiorni4 from "../assets/int-resheniq/6.webp";
import PrimaryHeading from "../components/typography/PrimaryHeading";
import PrimarySubtitle from "../components/typography/PrimarySubtitle";
import CustomButton from "../components/buttons/CustomButton";
import RectangleButton from "../components/buttons/RectangleButton";

function Allocations() {
  return (
    <>
      <section className="page-section bg-white">
        <div className="container mx-auto pt-5 pb-5 md:px-48">
          <PrimaryHeading heading={"Разпределения"} />
          <div className="text-center">
            <PrimarySubtitle
              align="center"
              text={<>Открийте разпределенията на различните етажи:</>}
            />
          </div>

          <div className="hidden justify-center gap-10 py-5 md:flex text-center">
            <div className="pb-1">
              <CustomButton
                link="https://theroyal.bg/assets/img/etaji/Floors.webp"
                text={"Партер"}
              />
            </div>
            <div className="pb-1">
              <CustomButton
                link="https://theroyal.bg/assets/img/etaji/Floors2.webp"
                text={"Етаж 1"}
              />
            </div>
            <div className="pb-1">
              <CustomButton
                link="https://theroyal.bg/assets/img/etaji/Floors3.webp"
                text={"Етаж 2"}
              />
            </div>
            <div className="pb-1">
              <CustomButton
                link="https://theroyal.bg/assets/img/etaji/Floors4.webp"
                text={"Етаж 3"}
              />
            </div>
            <div className="pb-1">
              <CustomButton
                link="https://theroyal.bg/assets/img/etaji/Floors5.webp"
                text={"Етаж 4"}
              />
            </div>
          </div>

          <div className="flex justify-center gap-10 py-10 md:hidden text-center">
            <div className="pb-1">
              <CustomButton
                link="https://theroyal.bg/assets/img/etaji/Floors.webp"
                text={"Партер"}
              />
            </div>
            <div className="pb-1">
              <CustomButton
                link="https://theroyal.bg/assets/img/etaji/Floors2.webp"
                text={"Етаж 1"}
              />
            </div>
          </div>

          <div className="flex justify-center gap-10 md:hidden text-center">
            <div className="pb-1">
              <CustomButton
                link="https://theroyal.bg/assets/img/etaji/Floors3.webp"
                text={"Етаж 2"}
              />
            </div>
            <div className="pb-1">
              <CustomButton
                link="https://theroyal.bg/assets/img/etaji/Floors4.webp"
                text={"Етаж 3"}
              />
            </div>
          </div>
        </div>

        <div className="container mx-auto  md:px-48">
          <div className="flex flex-wrap -mb-10 text-center">
            <div className="sm:w-1/2 mb-10 px-6">
              <div className="h-64 overflow-hidden">
                <img
                  alt="content"
                  className="object-cover object-center h-full w-full"
                  src={Interiorni1}
                />
              </div>
            </div>
            <div className="sm:w-1/2 mb-10 px-6">
              <div className="h-64 overflow-hidden">
                <img
                  alt="content"
                  className="object-cover object-center h-full w-full"
                  src={Interiorni2}
                />
              </div>
            </div>
            <div className="sm:w-1/2 mb-10 px-6">
              <div className="h-64 overflow-hidden">
                <img
                  alt="content"
                  className="object-cover object-center h-full w-full"
                  src={Interiorni3}
                />
              </div>
            </div>
            <div className="sm:w-1/2 mb-10 px-6">
              <div className="h-64 overflow-hidden">
                <img
                  alt="content"
                  className="object-cover object-center h-full w-full"
                  src={Interiorni4}
                />
              </div>
            </div>
          </div>
          <div className="py-12 text-center">
            <RectangleButton
              text={"Разгледайте още интериорни решения"}
              hrefTo={"/gallery"}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Allocations;
