import React, {FC} from 'react';
import PrimaryHeading from "../components/typography/PrimaryHeading";
import PrimarySubtitle from "../components/typography/PrimarySubtitle";

import Udobstvo1 from "../assets/udobstva/ICON-03.svg";
import Udobstvo2 from "../assets/udobstva/ICON-01.svg";
import Udobstvo3 from "../assets/udobstva/ICON-04.svg";
import Udobstvo4 from "../assets/udobstva/ICON-05.svg";
import Udobstvo5 from "../assets/udobstva/ICON-06.svg";
import Udobstvo6 from "../assets/udobstva/ICON-07.svg";
import Udobstvo7 from "../assets/udobstva/ICON-08.svg";
import Udobstvo8 from "../assets/udobstva/ICON-02.svg";

interface Props {
    pageRefs: any
}

const Facilities: FC<Props> = ({pageRefs}) => {
    return (
        <>
            <div ref={el => pageRefs.current = {...pageRefs.current, facilities: el}} id="facilities">
                <section className="page-section bg-white">
                    <div className="container mx-auto pt-5 pb-5 md:px-48">
                        <PrimaryHeading heading={"Удобства"}/>
                        <PrimarySubtitle
                            text={<>The Royal Residence е жилищен комплекс, предоставящ уют, комфорт и качествена среда
                                за живот. В полите на Витоша, с гледка към планината и светлините на града, той съчетава
                                в себе си съвременна архитектура, изпълнена с качествени и иновативни материали, нови
                                технологии и добро планиране, превръщайки го в мечтания дом. Комфортът на обитаване се
                                допълва от богато озеленяване и паркова среда, а за високото ниво на предложените услуги
                                говорят предвиденият луксозен ресторант и фитнес с представителното им фоайе. The Royal
                                Residence осигурява на своите обитатели и изключително високо ниво на сигурност с жива
                                охрана, видео наблюдение и 24-часов контрол на достъпа.</>}/>

                        <div
                            className="mx-auto my-auto text-center justify-center grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 md:gap-8 pt-10">
                            <div className="mx-auto">
                                <img src={Udobstvo1} className="w-24 md:w-36 mx-auto" alt="Лоби пространство"/>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Лоби пространство</div>
                            </div>
                            <div className="mx-auto">
                                <img src={Udobstvo2} className="w-24 md:w-36 mx-auto" alt="Управление на имоти"/>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Управление на имоти
                                </div>
                            </div>
                            <div className="mx-auto">
                                <img src={Udobstvo3} className="w-24 md:w-36 mx-auto" alt="Контролиран достъп"/>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Контролиран достъп</div>
                            </div>
                            <div className="mx-auto">
                                <img src={Udobstvo4} className="w-24 md:w-36 mx-auto" alt="Подземен паркинг"/>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Подземен паркинг</div>
                            </div>
                        </div>

                        <div
                            className="mx-auto my-auto text-center justify-center grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 md:gap-8 pt-4 md:pt-10">
                            <div className="mx-auto">
                                <img src={Udobstvo5} className="w-24 md:w-36 mx-auto" alt="Фитнес"/>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Фитнес</div>
                            </div>
                            <div className="mx-auto">
                                <img src={Udobstvo6} className="w-24 md:w-36 mx-auto" alt="Клуб-ресторант"/>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Клуб-ресторант
                                </div>
                            </div>
                            <div className="mx-auto">
                                <img src={Udobstvo7} className="w-24 md:w-36 mx-auto" alt="Консиерж услуги"/>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">Консиерж услуги</div>
                            </div>
                            <div className="mx-auto">
                                <img src={Udobstvo8} className="w-24 md:w-36 mx-auto" alt="24/7 охрана"/>
                                <div className="font-bold tracking-wide text-xl pt-2 text-gold">24/7 охрана</div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    );
}

export default Facilities;
