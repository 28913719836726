import React, {FC} from "react";

interface Props {
    text: JSX.Element;
    align?: string;
}

const PrimaryHeading: FC<Props> = ({
                                       text,
                                       align
                                   }) => {
    return (
        <div>
            <p className={align ? `mb-4 text-${align}` : `mb-4 text-justify px-5 md:px-2`}>
                {text}
            </p>
        </div>
    );
}

export default PrimaryHeading;
