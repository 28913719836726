import React, { useEffect } from "react";
import PrimaryHeading from "../components/typography/PrimaryHeading";

import BuildingNumbers1 from "../assets/accents/1.webp";
import BuildingNumbers2 from "../assets/accents/2.webp";
import BuildingNumbers3 from "../assets/accents/3.webp";

import RowImgText from "../components/RowImgText";
import RowTextImg from "../components/RowTextImg";
import PrimaryContainer from "../layouts/PrimaryContainer";
import NavbarSecond from "../layouts/NavbarSecond";

import ReceptionImg from "../assets/accents/recepciq2.webp";
import ParkingImg from "../assets/accents/parking2.webp";
import TerasaImg from "../assets/accents/terasa2.webp";
import GledkaVitosha from "../assets/accents/gledka-vitosha2.webp";
import VerandiImg from "../assets/accents/verandi2.webp";
import OtdihImg from "../assets/accents/kutove-za-otdih.webp";
import GledkaSofiq from "../assets/accents/gledka--kym-sofiq.webp";
import RestorantImg from "../assets/accents/restorant.webp";
import FitnessImg from "../assets/accents/fitnes.webp";
import Footer from "../layouts/Footer";
import LazyLoad from "react-lazy-load";

function Accents() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PrimaryContainer>
        <div className="the-royal pt-10">
          <NavbarSecond />
          <section className="p-10">
            <div className="container mx-auto pt-5 pb-5">
              <PrimaryHeading heading={"Акценти"} />

              <div>
                <LazyLoad height="400">
                  <img
                    src={BuildingNumbers1}
                    alt={"The Royal Residence Сграда"}
                    className={"w-100 h-100"}
                  />
                </LazyLoad>

                <div className="py-5">
                  <RowImgText
                    padding={"pb-10"}
                    title={"1/ РЕЦЕПЦИЯ"}
                    text="Постигнат е единен архитектурен образ между екстериора и интериора на общите пространства и фоайета, като са използвани същите иновативни материали, фасадното решение. Усещането за сполучлив детайл между форма, материал и цвят е подчертано и с оригинални светлинни решения."
                    img={ReceptionImg}
                  />
                  <RowTextImg
                    padding={"pb-10"}
                    title={"2/ РЕСТОРАНТ"}
                    text="Проектираният частен клубресторант ще предоставя на живущите първокласно място, на което да се насладят на селектирано меню от ястия. Достъпът ще е ограничен до живущите в комплекса и поканени от тях гости."
                    img={RestorantImg}
                  />
                  <RowImgText
                    padding={"pb-10"}
                    title={"3/ ФИТНЕС"}
                    text="На разположение на обитателите на комплекса ще е фитнес от висок клас с всички нужни уреди за пълноценна тренировка, дори и за най-взискателните спортисти."
                    img={FitnessImg}
                  />
                </div>
              </div>

              <div>
                <LazyLoad height="400">
                  <img
                    src={BuildingNumbers2}
                    alt={"The Royal Residence Сграда"}
                    className={"w-100 h-100"}
                  />
                </LazyLoad>

                <div className="py-5">
                  <RowImgText
                    padding={"pb-10"}
                    title={"4/ ПАРКИНГ"}
                    text="Паркирането на автомобили е решено изцяло посредством подземна инфраструктура с контролиран достъп. На разположение са допълнителни паркоместа за гости пред входа за рецепция."
                    img={ParkingImg}
                  />
                  <RowTextImg
                    padding={"pb-10"}
                    title={"5/ ТЕРАСОВИДНА ГРАДИНА"}
                    text="Модерно решение за организиране на дворното пространство с богато озеленяване и подпорни стени с габиони с LED подсветки."
                    img={TerasaImg}
                  />
                  <RowImgText
                    padding={"pb-10"}
                    title={"6/ ГЛЕДКА КЪМ ВИТОША"}
                    text="Прекрасното разположение на комплекса позволява неограничен обзор към Витоша планина, извисяваща се непосредствено на юг."
                    img={GledkaVitosha}
                  />
                </div>
              </div>

              <div>
                <LazyLoad height="400">
                  <img
                    src={BuildingNumbers3}
                    alt={"The Royal Residence Сграда"}
                    className={"w-100 h-100"}
                  />
                </LazyLoad>

                <div className="py-5">
                  <RowImgText
                    padding={"pb-10"}
                    title={"7/ ВЕРАНДИ"}
                    text="Всеки от апартаментите на първи етаж ще разполага с частна веранда, осигуряваща повече пространство и лесен достъп до изкусно аранжираните градини."
                    img={VerandiImg}
                  />
                  <RowTextImg
                    padding={"pb-10"}
                    title={"8/ КЪТОВЕ ЗА ОТДИХ"}
                    text="Комфортът на обитаване ще се допълни и от частно парково пространство с богато озеленяване, поддържани градини, удобни пейки и осветени алеи."
                    img={OtdihImg}
                  />
                  <RowImgText
                    title={"9/ ГЛЕДКА КЪМ СОФИЯ"}
                    text="Разположението на комплекса осигурява и панорамна гледка към столицата."
                    img={GledkaSofiq}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </PrimaryContainer>
      <Footer />
    </>
  );
}

export default Accents;
