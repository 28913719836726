import React from 'react';
import LogoImg from '../assets/header-logo.png';
import {Link} from "react-router-dom";

function Logo() {
    return (
        <Link to="/"><img src={LogoImg} alt={"The Royal Residence"} /></Link>
    );
}

export default Logo;
