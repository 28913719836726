import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import Gallery from "./pages/Gallery";
import Accents from "./pages/Accents";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Routes>
          <Route path={"/"} element={<Home />}></Route>
          <Route path={"/gallery"} element={<Gallery />}></Route>
          <Route path={"/accents"} element={<Accents />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
