import React, {FC} from "react";
import {Link} from "react-router-dom";

interface Props {
    text: string;
    hrefTo: string;
    className?: string;
}

const RectangleButton: FC<Props> = ({
                                        text,
                                        hrefTo,
                                        className
                                    }) => {

    return (
        <Link
            to={hrefTo}
            className={`px-1 md:px-5 py-5 bg-gold text-white hover:opacity-90 ease-in duration-150 text-sm md:text-lg tracking-wider font-bolder ${className}`}>
            {text}
        </Link>
    );
}

export default RectangleButton;

