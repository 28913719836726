import React, {FC} from "react";
import LazyLoad from "react-lazy-load";

interface Props {
    text?: string;
    text2?: string;
    title?: string;
    img?: string;
    imgAlt?: string;
    padding?: string;
    textSize?: string;
}

const RowTextImg: FC<Props> = ({
                                   text,
                                   text2,
                                   title,
                                   img,
                                   imgAlt,
                                   padding,
                                   textSize,
                               }) => {
    return (
        <>
            <div className={`grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 ${padding}`}>
                <div
                    className="relative h-64 overflow-hidden sm:h-80 lg:order-first md:h-80"
                >
                    <LazyLoad height="400">
                        <img
                            alt={imgAlt}
                            src={img}
                            className="absolute inset-0 h-full w-full object-cover"
                        />
                    </LazyLoad>
                </div>

                <div className="lg:py-24 text-center ">
                    <h2 className="text-3xl font-bold sm:text-4xl">{title}</h2>

                    <p className={`${textSize} mt-4 text-gray-600 text-justify`}>
                        {text}
                    </p>

                    <p className="mt-1 text-gray-600 text-justify">
                        {text2}
                    </p>
                </div>
            </div>
        </>
    );
};

export default RowTextImg;
