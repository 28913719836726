import React, {FC} from 'react';
import PrimaryHeading from "../components/typography/PrimaryHeading";
import PrimarySubtitle from "../components/typography/PrimarySubtitle";
import Map from "../assets/maphover.png";

interface Props {
    pageRefs: any
}

const Location: FC<Props> = ({pageRefs}) => {
    return (
        <>
            <div ref={el => pageRefs.current = {...pageRefs.current, mqsto: el}} id="mqsto">
                <section className="page-section bg-white" id="location">
                    <div className="container mx-auto">
                        <div className="px-5 md:px-60">
                            <PrimaryHeading heading={"Място"}/>
                            <PrimarySubtitle
                                text={<>The Royal Residence се намира в подножието на Витоша, в квартал Бояна на тихата
                                    и
                                    спокойна пресечка на улици „Абанос“ и „Добри Божилов“. В непосредствена близост до
                                    вече
                                    завършения комплекс The Royal Park Residence.</>}/>
                            <img src={Map} alt="The Royal Residence Местоположение"/>

                            <div className="w-full relative mb-4 mt-4 hidden md:block">
                                <video
                                    autoPlay
                                    muted
                                    className={"mx-auto "}
                                    controls>
                                    <source src={"https://www.theroyal.bg/new-building.mp4"}/>
                                </video>
                            </div>

                            <div className="w-full relative mb-4 mt-4 block md:hidden">
                                <video
                                    autoPlay
                                    muted
                                    className={"mx-auto "}
                                    controls>
                                    <source src={"https://www.theroyal.bg/new-building-vertical.mp4"}/>
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Location;
