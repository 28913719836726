import React, { useRef, useState } from "react";
import PrimaryContainer from "../layouts/PrimaryContainer";
import ParallaxDivider from "../components/ParallaxDivider";

import Project from "./Project";
import Sketch from "./Sketch";
import Location from "./Location";
import Navbar from "../layouts/Navbar";
import Facilities from "./Facilities";
import Materials from "./Materials";
import Allocations from "./Allocations";
import FinishedProjects from "./FinishedProjects";
import Footer from "../layouts/Footer";

import Divider1 from "../assets/dividers/1.webp";
import Divider2 from "../assets/dividers/2.webp";
import Divider5 from "../assets/dividers/5.webp";
import Divider3 from "../assets/dividers/3.webp";
import Divider4 from "../assets/dividers/4.webp";
import Divider6 from "../assets/dividers/6.webp";
import HeaderVideoPoster from "../assets/header-video-poster.png";
import HeaderPNG from "../assets/header-png.png";

const Home = () => {
  const [isVideoEnd, setVideoEnd] = useState(false);
  const pageRefs = useRef({});

  function onVideoEnded() {
    setVideoEnd(true);
  }

  return (
    <>
      <div>
        <Navbar pageRefs={pageRefs} />
        <PrimaryContainer>
          <div className="the-royal">
            <div
              ref={(el) =>
                (pageRefs.current = { ...pageRefs.current, home: el })
              }
            >
              <section id={"home"} className="bg-black">
                <div className="hidden md:block">
                  <div className="w-100 h-100 mx-auto flex items-center justify-center flex-col relative">
                    <div className="sm:hidden md:block">
                      <video
                        autoPlay
                        muted
                        onEnded={onVideoEnded}
                        className={isVideoEnd ? "hidden" : "w-full"}
                      >
                        <source
                          src="https://www.theroyal.bg/header.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                  <div className="relative">
                    <img
                      src={HeaderVideoPoster}
                      alt="header"
                      className={
                        isVideoEnd ? "object-cover h-screen w-full" : "hidden"
                      }
                    />
                  </div>
                </div>
                <div className="visible md:hidden">
                  <div className="relative">
                    <img
                      src={HeaderPNG}
                      alt="header"
                      className="h-screen object-contain"
                    />
                  </div>
                </div>
              </section>
            </div>
            <Project />
            <Sketch />
            <ParallaxDivider img={Divider1} />
            <Location pageRefs={pageRefs} />
            <ParallaxDivider img={Divider5} />
            <Facilities pageRefs={pageRefs} />
            <ParallaxDivider img={Divider3} />
            <Materials pageRefs={pageRefs} />
            <ParallaxDivider img={Divider4} />
            <Allocations />
            <ParallaxDivider img={Divider2} />
            <FinishedProjects />
            <ParallaxDivider img={Divider6} />
            <Footer />
          </div>
        </PrimaryContainer>
      </div>
    </>
  );
};

export default Home;
