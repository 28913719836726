import React, {FC} from "react";

interface Props {
    heading: string;
}

const PrimaryHeading: FC<Props> = ({
                                       heading,
                                   }) => {
    return (
        <div>
            <h2 className="text-center text-gold font-bold text-3xl md:text-5xl uppercase">{heading}</h2>
            <hr className="divider"/>
        </div>
    );
}

export default PrimaryHeading;
